import { Box, Button, ButtonGroup, Divider, Link, Paper, Stack, Typography } from '@mui/material'
import { AutoRslaWorksheet } from '@src/data/types/AutoRslaWorksheet'
import { useTranslation } from 'react-i18next'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import { FormatCurrency } from '@src/services/Formatter'

type VehiclePriceCategory = 'xclean' | 'clean' | 'avg' | 'rough'

type Props = {
  worksheet: AutoRslaWorksheet
}
const AutoRslaWorksheetInformationSection = ({ worksheet }: Props) => {
  const { t } = useTranslation()

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Box sx={{ m: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={2}>
            <ButtonGroup variant="outlined" size="large">
              <Button id="btnEditWorksheet" component={Link} variant="outlined" disabled>
                {t('worksheet.financing')}
              </Button>
            </ButtonGroup>
            <CarRepairIcon fontSize="large" color="primary" />
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems="top"
          marginTop={1}
          justifyContent="space-evenly"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.vin')} : {worksheet?.vehicle?.vin}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.vehicle')} : {worksheet?.vehicle?.make} {worksheet?.vehicle?.model}{' '}
              {worksheet?.vehicle?.series} {worksheet?.vehicle?.year}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.mileage')} : {worksheet?.vehicle?.mileage}{' '}
              {worksheet?.vehicle?.mileage !== undefined && 'KM'}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              CBB :{' '}
              {worksheet?.vehicle?.pricingCategory
                ? t(`worksheet.vehiclePriceCategory.${worksheet?.vehicle?.pricingCategory as VehiclePriceCategory}`)
                : ''}
            </Typography>
            <Typography variant="subtitle1">
              {t('worksheet.merchantEstimatedValue')} :{' '}
              {worksheet?.vehicle?.brokerEstimatedValue
                ? FormatCurrency(worksheet?.vehicle?.brokerEstimatedValue)
                : 'N/A'}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentFrequency')} : {worksheet && t(`worksheet.${worksheet?.paymentFrequency}`)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.term')} : {worksheet?.term} {worksheet?.term !== undefined && t('worksheet.month')}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentAmount')} : {FormatCurrency(worksheet?.paymentAmountForFrequency)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.plan')} :{' '}
              {worksheet?.paymentPlanId ? t(`enum.ePaymentPlan.${worksheet?.paymentPlanId}`) : ''}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.repairsAmount')} : {FormatCurrency(worksheet?.totalAmountFinanced)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.fees')} : {FormatCurrency(worksheet?.adminFee)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.rsla')} : {FormatCurrency(worksheet?.lienAmount)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.carInsurance')} : {FormatCurrency(worksheet?.roadsideAmount)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.clientFees')} : {FormatCurrency(worksheet?.clientManagementFee)}
            </Typography>
          </span>
        </Stack>
      </Box>
    </Paper>
  )
}

export default AutoRslaWorksheetInformationSection
