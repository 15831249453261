import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'
import allApis from '../../../data/api'
import { AdditionnalBankruptcies, Applicant, CreditApplication, FullApplicantSchema } from '../../../data/types'
import { ActionsDialog } from '../../../components'
import { reportErrorToConsole } from '../../../services/error-logger'
import BankruptcyComponent from './Bankruptcy'
import { CreditReport } from '../../../data/types/CreditReportSchema'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import ReportedBankruptcy from './ReportedBankruptcy'

type Props = {
  open: boolean
  creditApplication: CreditApplication
  applicant: Applicant | undefined
  onConfirm: (data: Applicant) => void
  onCancel: () => void
  disableConfirm?: boolean
}

const EditBankruptciesDialog = ({ applicant, creditApplication, onConfirm, onCancel, open, disableConfirm }: Props) => {
  const { t } = useTranslation()
  const apiClient = useAppSelector(appSelectors.getApiClient)
  const [currentApplicantCreditReport, setCurrentApplicantCreditReport] = React.useState<CreditReport | null>()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Applicant>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: applicant,
    resolver: yupResolver(FullApplicantSchema),
  })

  const {
    fields: currentAdditionnalBankruptciesFields,
    append: additionnalBankruptciesAppend,
    remove: additionnalBankruptciesRemove,
  } = useFieldArray({
    name: 'additionnalBankruptcies',
    control,
  })

  React.useEffect(() => {
    reset(applicant)
    if (applicant?.hardHitReport?.creditReportId && apiClient) {
      allApis.credit
        .getCreditReportByCreditReportId(apiClient, {
          financingProgramId: creditApplication.financingProgramId,
          creditApplicationId: creditApplication.id,
          creditReportId: applicant.hardHitReport.creditReportId,
        })
        .then((applicantReport) => setCurrentApplicantCreditReport(applicantReport.creditReport))
        .catch(reportErrorToConsole)
    }
  }, [
    applicant,
    reset,
    apiClient,
    creditApplication.id,
    creditApplication.financingProgramId,
    applicant?.hardHitReport?.creditReportId,
  ])

  return (
    <ActionsDialog
      disabled={disableConfirm}
      title={
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
          <Typography>{t('creditReport.bankruptcyHistory')}</Typography>
          <Button
            variant="contained"
            color="success"
            endIcon={<Add />}
            onClick={() => additionnalBankruptciesAppend({ id: nanoid() } as AdditionnalBankruptcies)}
          >
            {t('common.add')}
          </Button>
        </Stack>
      }
      open={open}
      onCancel={() => {
        reset(applicant)
        onCancel()
      }}
      onConfirm={handleSubmit(onConfirm, reportErrorToConsole)}
    >
      {currentApplicantCreditReport?.bankruptcies &&
        currentApplicantCreditReport.bankruptcies.map((bankruptcy) => (
          <ReportedBankruptcy bankruptcy={bankruptcy} key={bankruptcy.caseNumber} />
        ))}
      {currentAdditionnalBankruptciesFields.map((bankruptcy, index) => (
        <BankruptcyComponent
          index={index}
          register={register}
          error={errors?.additionnalBankruptcies}
          control={control}
          key={bankruptcy.id}
          isSyndicReported
          onDeleteClicked={() => additionnalBankruptciesRemove(index)}
          fieldName="additionnalBankruptcies"
        />
      ))}
    </ActionsDialog>
  )
}

export default React.memo(EditBankruptciesDialog)
