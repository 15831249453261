import creditApplicationApi from './credit-application/credit-application-api'
import configApi from './config/config-api'
import userApi from './user/user-api'
import creditTaskApi from './credit-task/credit-task-api'
import creditTaskCommentApi from './credit-task-comment/credit-task-comment-api'
import creditDecisionApi from './final-credit-decision/final-credit-decision-api'
import worksheetApi from './auto-worksheet/auto-worksheet-api'
import banishmentApi from './banisment/banishment'
import creditTaskDashboardEntriesApi from './credit-task-dashboard-entry/credit-task-DashboardEntries-api'
import previousApplicationApi from './previous-application/previous-application-api'
import vehicleApi from './vehicle/vehicle-api'
import documentApi from './document/document-api'
import messageApi from './message/message-api'
import creditApplicationHistoryApi from './credit-application-history/credit-application-history-api'
import creditDashboardEntriesApi from './credit-dashboard-entry/credit-DashboardEntries-api'
import contractApi from './credit-application-contract/credit-application-contract'
import fullCreditApplicationApi from './full-credit-application/full-credit-application-api'
import refinancingApi from './refinancing-api'
import loanApi from './credit-loan/credit-application-loan'
import bankAccountRequestApi from './bank-account-request/bank-account-request-api'
import personalLoanWorksheetApi from './personal-loan-worksheet/personal-loan-worksheet-api'
import availableCreditApi from './available-credit-api'
import electronicSignatureApi from './electronic-sign/electronic-sign-api'
import customerReferenceApi from './customer-reference/customer-reference-api'
import loanCustomerApi from './loan-customer/loan-customer-api'
import reportApi from './report/report-api'
import b2cWorksheetApi from './b2c-worksheet/b2c-worksheet-api'
import autoRslaWorksheetApi from './auto-rsla-worksheet/auto-rsla-worksheet-api'

export default {
  credit: creditApplicationApi,
  config: configApi,
  user: userApi,
  creditTask: creditTaskApi,
  creditTaskComment: creditTaskCommentApi,
  creditDecision: creditDecisionApi,
  worksheet: worksheetApi,
  document: documentApi,
  banishment: banishmentApi,
  creditTaskDashboardEntries: creditTaskDashboardEntriesApi,
  previousapplication: previousApplicationApi,
  vehicle: vehicleApi,
  message: messageApi,
  history: creditApplicationHistoryApi,
  creditDashboardEntries: creditDashboardEntriesApi,
  contract: contractApi,
  fullCredit: fullCreditApplicationApi,
  refinancing: refinancingApi,
  loan: loanApi,
  bankAccountRequest: bankAccountRequestApi,
  personalLoan: personalLoanWorksheetApi,
  b2cWorksheet: b2cWorksheetApi,
  autoRslaWorsheet: autoRslaWorksheetApi,
  availableCredit: availableCreditApi,
  electronicSignature: electronicSignatureApi,
  customerReference: customerReferenceApi,
  loanCustomer: loanCustomerApi,
  report: reportApi,
}
