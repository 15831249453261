import { Button, Card, CardContent, Grid, Paper, Typography, Stack, Box, Dialog } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { cloneDeep } from 'lodash-es'
import { Done as DoneIcon, DeleteForever, FindInPage, Queue, Cancel, South, Category, Gavel } from '@mui/icons-material'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ActionsDialog, Breadcrumb, PageSpinner, SelectComponent, TabPanel } from '../../components'
import { TRootState, useAppSelector } from '../../data/store'
import { creditEffects, creditSelectors } from '../../data/store/CreditApplication'
import {
  Document,
  DocumentPage,
  EApplicantType,
  EDocumentStatus,
  EDocumentPageStatus,
  EDocumentType,
  SelectValueListItem,
  EFinancingProgram,
  EOriginSystemId,
  EApplicantTypeList,
} from '../../data/types'
import Header from './components/header'
import Thumbnails from './components/thumbnails'
import { DocumentPageDisplayInfo } from '../../data/types/DocumentPageDisplayInfo'
import { documentEffects, documentSelectors } from '../../data/store/Document'
import { appSelectors } from '../../data/store/AppStore'
import { TranslatedEnum } from '../../data/types/TranslatedEnum'
import DocumentTypeSelectionSection from './components/documentTypeSelectionSection'
import DocumentDecisionSection from './components/documentDecisionSection'
import { userSelectors } from '../../data/store/UserStore'

const CATEGORIZATION_MODE = 0
const DECISION_MODE = 1

const SplitDocumentPage = () => {
  const { t } = useTranslation()
  const { id, financingProgramId } = useParams<{ id: string; financingProgramId: EFinancingProgram }>()
  const [searchParams] = useSearchParams()
  const documentId = searchParams.get('docIds')
  const ids = useMemo(() => documentId?.split(';') ?? [], [documentId])
  const navigate = useNavigate()
  const dispatchEffect = useSideEffect()

  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const multipleRequiredDocs = useAppSelector(documentSelectors.getMultipleRequiredDocuments)
  const creditAppDocs = useAppSelector(documentSelectors.getDocumentList)
  const creditAppDocumentPageDisplayInfos = useAppSelector(documentSelectors.getDocumentPageDisplayInfos)
  const documentDecisions = useAppSelector((state: TRootState) => documentSelectors.getDocumentDecisions(state, ids))
  const creditAppRequiredDocs = useAppSelector(documentSelectors.getDocumentRequestedList)
  const documentPages = useAppSelector(documentSelectors.getDocumentPageList)
  const isLoadingCreditApp = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const isLoadingDocumentPages = useAppSelector(documentSelectors.isLoadingDocumentPages)
  const isApplyingTransformation = useAppSelector(documentSelectors.isApplyingTransformation)
  const isMakingDecisions = useAppSelector(documentSelectors.isMakingDecisions)
  const isLoading = isLoadingCreditApp || isLoadingDocumentPages || isApplyingTransformation || isMakingDecisions

  const [filteredDocumentPagesInfos, setFilteredDocumentPagesInfos] = React.useState<DocumentPageDisplayInfo[]>([])
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const documentTypes = useAppSelector((state: TRootState) => appSelectors.getDocumentTypes(state, financingProgramId))
  const [selectedPagesIds, setSelectedPagesIds] = React.useState<string[]>([])
  const [applicantType, setApplicantType] = React.useState<EApplicantType>(EApplicantType.Applicant)
  const editDisabled = creditApplication?.editLocked === true

  const [activeMode, setActiveMode] = React.useState<number>(DECISION_MODE)
  const [activeDocumentIndex, setActiveDocumentIndex] = React.useState<number>(0)
  const [status, setStatus] = React.useState<EDocumentStatus>()
  const [comment, setComment] = React.useState<string | null>('')
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [hasChange, setHasChange] = React.useState<boolean>(false)
  const [activePageFilters, setActivePageFilters] = React.useState<string[]>([
    EDocumentPageStatus.New,
    EDocumentPageStatus.Reviewed,
  ])
  const [toggleBanners, setToggleBanners] = React.useState<boolean>(false)
  const [pagesPerRow, setPagesPerRow] = React.useState<number>(2)
  const [isDraggable, setIsDraggable] = React.useState<boolean>(false)
  const [globalDocumentType, setGlobalDocumentType] = React.useState<EDocumentType>(EDocumentType.Other)
  const [globalApplicantType, setGlobalApplicantType] = React.useState<EApplicantType>(EApplicantType.Applicant)
  const [quickTypePanelOpen, setQuickTypePanelOpen] = React.useState<boolean>(false)
  const [hasDecisionMade, setHasDecisionMade] = React.useState<boolean>(false)

  const user = useAppSelector(userSelectors.getUser)
  const userCanEditDocument = user?.rights.canManageDocuments === true

  React.useEffect(() => {
    if (ids?.length === 1 && creditAppDocs?.length > 0) {
      setGlobalDocumentType(creditAppDocs.find((d) => d.id === documentId)?.typeId ?? EDocumentType.Other)
      setGlobalApplicantType(creditAppDocs.find((d) => d.id === documentId)?.applicantType ?? EApplicantType.Applicant)
    }
  }, [ids, creditAppDocs, documentId])

  React.useEffect(() => {
    if (id && financingProgramId) {
      dispatchEffect(creditEffects.getById(id, financingProgramId)).catch(reportErrorToConsole)
      dispatchEffect(documentEffects.getDocumentPages(id, financingProgramId)).catch(reportErrorToConsole)
    }
  }, [id, dispatchEffect, financingProgramId])

  React.useEffect(() => {
    let filtered: DocumentPageDisplayInfo[] = []
    if (activePageFilters.length === 0) {
      filtered = creditAppDocumentPageDisplayInfos.filter((p) => ids?.includes(p.documentId))
    } else
      filtered = creditAppDocumentPageDisplayInfos.filter(
        (p) => ids?.includes(p.documentId) && activePageFilters.includes(p.pageStatus),
      )
    setFilteredDocumentPagesInfos(filtered)
  }, [ids, creditAppDocumentPageDisplayInfos, activePageFilters, activeMode])

  React.useEffect(() => {
    if (documentDecisions.length > 0 && activeMode !== CATEGORIZATION_MODE && activeDocumentIndex >= 0) {
      let concernedPages: DocumentPageDisplayInfo[] = []
      if (activePageFilters.length === 0) {
        concernedPages = creditAppDocumentPageDisplayInfos.filter(
          (p) => p.documentId === documentDecisions[activeDocumentIndex]?.documentId,
        )
      } else
        concernedPages = creditAppDocumentPageDisplayInfos.filter(
          (p) =>
            p.documentId === documentDecisions[activeDocumentIndex]?.documentId &&
            activePageFilters.includes(p.pageStatus),
        )
      setFilteredDocumentPagesInfos(concernedPages)
      setGlobalDocumentType(
        creditAppDocs.find((d) => d.id === documentDecisions[activeDocumentIndex]?.documentId)?.typeId ??
          EDocumentType.Other,
      )
      setGlobalApplicantType(
        creditAppDocs.find((d) => d.id === documentDecisions[activeDocumentIndex]?.documentId)?.applicantType ??
          EApplicantType.Applicant,
      )
    }
  }, [
    creditAppDocumentPageDisplayInfos,
    documentDecisions,
    activeDocumentIndex,
    ids,
    activeMode,
    activePageFilters,
    creditAppDocs,
  ])

  const applyDocumentTypeToSelectedPages = (typeId: number, subKey: string) => {
    setHasChange(true)
    if (selectedPagesIds.length > 0) {
      selectedPagesIds.forEach((pageId) => {
        const pageIndex = filteredDocumentPagesInfos.findIndex((x) => x.id === pageId)
        if (pageIndex !== -1) {
          filteredDocumentPagesInfos[pageIndex] = {
            ...filteredDocumentPagesInfos[pageIndex],
            documentType: typeId,
            applicantType,
            subKey,
          }
        }
      })

      setSelectedPagesIds([])
      setFilteredDocumentPagesInfos(filteredDocumentPagesInfos)
    }
  }

  const updateUrl = (documentIds: string[]) => {
    const newIds = Array.from(new Set(documentIds))
    const { location } = window
    const updateSearch = new URLSearchParams(location.search)
    updateSearch.set('docIds', newIds.join(';'))

    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    setHasChange(false)
    setActiveDocumentIndex(newIds.length - 1)
    navigate(newUrl, { replace: true })
    setActiveMode(DECISION_MODE)
  }

  const buildTransformation = () => {
    const data = cloneDeep(creditAppDocs)
    data.forEach((doc) => {
      doc.pages = documentPages.filter((p) => p.documentId === doc.id)
    })
    filteredDocumentPagesInfos.forEach((page) => {
      const pageSourceDocument = data.findIndex((doc) => doc.pages.some((p) => p.id === page.id))
      const targetDocuments = data.filter(
        (doc) =>
          doc.applicantType === page.applicantType && doc.typeId === page.documentType && doc.subKey === page.subKey,
      )
      let pageTargetDocument: number
      if (targetDocuments.length === 1) {
        pageTargetDocument = data.findIndex(
          (doc) =>
            doc.applicantType === page.applicantType && doc.typeId === page.documentType && doc.subKey === page.subKey,
        )
      } else {
        pageTargetDocument = data.findIndex(
          (doc) =>
            doc.applicantType === page.applicantType &&
            doc.typeId === page.documentType &&
            doc.status !== EDocumentStatus.Refused &&
            doc.subKey === page.subKey &&
            targetDocuments.some((d) => d.id === doc.id),
        )
      }

      const docPage = {
        id: page.id,
        rotation: page.rotation,
        status: page.pageStatus,
        documentId: page.documentId,
        pageNumber: page.pageNumber,
        thumbnailUrl: page.src,
        creditApplicationId: id,
      } as DocumentPage

      if (pageSourceDocument !== pageTargetDocument) {
        data[pageSourceDocument].pages = data[pageSourceDocument].pages.filter((p) => p.id !== page.id)

        if (pageTargetDocument !== -1) data[pageTargetDocument].pages.push(docPage)
        else if (pageTargetDocument === -1) {
          const newDocument: Document = {
            id: null,
            typeId: page.documentType,
            pages: [docPage],
            creditApplicationId: id,
            status: EDocumentStatus.AwaitingDocument,
            applicantType: page.applicantType,
            fileName: 'Split',
            subKey: page.subKey,
          } as Document

          data.push(newDocument)
        }
      } else {
        const pageIndex = data[pageSourceDocument].pages.findIndex((p) => p.id === page.id)
        data[pageSourceDocument].pages[pageIndex] = docPage
      }
    })

    return data
  }

  const handleNoModificationBeforeDecision = () => {
    setHasChange(false)
    setIsOpen(false)
    setActiveMode(DECISION_MODE)
  }

  const updateDecision = () => {
    if (documentDecisions[activeDocumentIndex]) {
      if (status === EDocumentStatus.Approved || status === EDocumentStatus.AwaitingApproval)
        documentDecisions[activeDocumentIndex].refusalReason = null
      else documentDecisions[activeDocumentIndex].refusalReason = comment

      documentDecisions[activeDocumentIndex].status = status!
    }
  }

  const isAnyRefusedDocumentMissingComment = (): boolean => {
    return documentDecisions.some((d) => {
      const refusedOrIncomplete = d.status === EDocumentStatus.Incomplete || d.status === EDocumentStatus.Refused
      return refusedOrIncomplete && !d.refusalReason
    })
  }

  const makeDecisions = () => {
    updateDecision()

    if (isAnyRefusedDocumentMissingComment()) {
      return
    }

    dispatchEffect(documentEffects.makeDecisions(documentDecisions, id!, financingProgramId!))
      .then(() =>
        navigate(`/Applications/${creditApplication?.financingProgramId}/${creditApplication?.id}/view`, {
          replace: true,
        }),
      )
      .catch(reportErrorToConsole)
  }

  const sendTransformation = () => {
    if (hasDecisionMade && activeMode === DECISION_MODE) {
      makeDecisions()
    } else if (hasChange && activeMode === CATEGORIZATION_MODE) {
      const data = buildTransformation()
      const currentCreditAppDocIds = creditAppDocs.map((d) => d.id)
      dispatchEffect(documentEffects.applyDocsTransformation(data, id!, financingProgramId!))
        .then((updated) => {
          const brandNewIds = updated.documents.filter((d) => !currentCreditAppDocIds.includes(d.id)).map((d) => d.id!)
          const deletedIds = currentCreditAppDocIds.filter((i) => !updated.documents.map((d) => d.id!).includes(i!))
          const idsWeStillWant = ids.filter((i) => !deletedIds.includes(i))
          updateUrl([...idsWeStillWant, ...brandNewIds])
        })
        .catch(reportErrorToConsole)
    }
  }

  const handleModificationsBeforeDecision = () => {
    sendTransformation()

    setActiveMode(DECISION_MODE)
    setIsOpen(false)
  }

  const onModeChange = (modeIndex: number) => {
    if (modeIndex === CATEGORIZATION_MODE) {
      setToggleBanners(true)
      setActiveMode(modeIndex)
      setHasChange(false)
    } else if (hasChange) {
      setIsOpen(true)
      setToggleBanners(false)
      setIsDraggable(false)
    } else {
      handleNoModificationBeforeDecision()
      setToggleBanners(false)
      setIsDraggable(false)
    }
  }

  const isRefusedDocumentMissingComment = (): boolean => {
    return (status === EDocumentStatus.Incomplete || status === EDocumentStatus.Refused) && !comment
  }

  const handleNext = (selectedDocumentId: string) => {
    if (isRefusedDocumentMissingComment()) {
      return
    }
    updateDecision()
    setActiveDocumentIndex(documentDecisions.findIndex((d) => d.documentId === selectedDocumentId))
  }

  const maxSteps = useMemo(() => {
    return documentDecisions.length
  }, [documentDecisions])

  const onResizingChange = (value: number) => {
    const newValue = 7 - value
    setPagesPerRow(newValue)
  }

  const handleTypeChange = () => {
    if (ids) {
      const data = cloneDeep(creditAppDocs)
      data.forEach((doc) => {
        doc.pages = documentPages.filter((p) => p.documentId === doc.id)
      })
      const pagesToAddToTheNewDoc: DocumentPage[] = []
      filteredDocumentPagesInfos.forEach((page) => {
        const concernedPage = documentPages.find((p) => p.id === page.id)
        if (concernedPage) {
          pagesToAddToTheNewDoc.push(concernedPage)
          const concernedDocument = data.find((d) => d.id === concernedPage?.documentId)
          if (concernedDocument) concernedDocument.pages = []
        }
      })

      const concernedDocumentRequest = creditAppRequiredDocs.find(
        (r) => r.typeId === globalDocumentType && r.applicantType === globalApplicantType,
      )
      const newDocument: Document = {
        id: null,
        typeId: globalDocumentType,
        pages: pagesToAddToTheNewDoc,
        creditApplicationId: id,
        status: EDocumentStatus.AwaitingDocument,
        applicantType: globalApplicantType,
        fileName: 'Split',
        subKey: concernedDocumentRequest?.subKey,
      } as Document

      data.push(newDocument)

      const currentDocument = data.find((d) => d.id === documentDecisions[activeDocumentIndex]?.documentId)
      dispatchEffect(documentEffects.applyDocsTransformation(data, id!, financingProgramId!))
        .then((updated) => {
          const currentIds = data.map((x) => x.id)
          const newDoc = updated.documents.find((d) => !currentIds.includes(d.id))
          if (newDoc) {
            const newId = newDoc.id!
            updateUrl(
              activeMode === CATEGORIZATION_MODE ? [newId] : [...ids.filter((d) => d !== currentDocument?.id), newId],
            )
          }
        })
        .catch(reportErrorToConsole)

      setQuickTypePanelOpen(false)
    }
  }

  const getDecisionTitle = (index: number) => {
    if (index >= maxSteps || index < 0) return ''
    const activeDecision = documentDecisions[index]
    const part1 = documentTypes[activeDecision.documentType][lang]
    const part2 = multipleRequiredDocs.find(
      (rd) =>
        rd.applicantType === activeDecision.applicantType &&
        rd.typeId === activeDecision.documentType &&
        rd.subKey === activeDecision.subKey,
    )?.labelSuffix

    return part2 ? String(`${part1}-${part2}`) : String(part1)
  }

  const getModifiedDecisionsCount = () => {
    const docStatusMap = new Map<string, string>(creditAppDocs.filter((d) => d.id).map((d) => [d.id!, d.status!]))

    const count = documentDecisions.reduce((acc, d) => {
      const docStatus = docStatusMap.get(d.documentId)
      return acc + (docStatus !== d.status ? 1 : 0)
    }, 0)

    return count
  }

  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
    {
      path: `/Applications/${creditApplication?.financingProgramId}/${creditApplication?.id}/view`,
      label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber ?? ''}`),
    },
    {
      path: '#',
      label: `Document ${activeMode === CATEGORIZATION_MODE ? t('document.categorisation') : t('document.decision')}`,
    },
  ]

  const isForClient =
    financingProgramId === EFinancingProgram.Personal &&
    creditApplication?.originSystemId !== EOriginSystemId.MerchantDashboard
  const applicantLang = isForClient
    ? creditApplication?.applicant.languageId
    : creditApplication?.correspondanceLanguageId
  const coapplicantLang = isForClient
    ? (creditApplication?.coApplicant?.languageId ?? creditApplication?.correspondanceLanguageId)
    : creditApplication?.correspondanceLanguageId

  return (
    <>
      <PageSpinner isLoading={isLoading} withBackdrop />

      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb trees={breadCrumbs} />
        <Stack direction="row" sx={{ width: '30rem', maxHeight: '3rem', margin: '1rem' }} spacing={2}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => onModeChange(activeMode === DECISION_MODE ? CATEGORIZATION_MODE : DECISION_MODE)}
            startIcon={activeMode === CATEGORIZATION_MODE ? <Gavel color="primary" /> : <Category color="primary" />}
          >
            {activeMode === CATEGORIZATION_MODE ? t('common.cancel') : t('document.categorisation')}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="success"
            endIcon={<DoneIcon />}
            onClick={sendTransformation}
            disabled={
              editDisabled ||
              isRefusedDocumentMissingComment() ||
              (activeMode === DECISION_MODE && getModifiedDecisionsCount() === 0) ||
              (activeMode === CATEGORIZATION_MODE && !hasChange)
            }
          >
            {activeMode === CATEGORIZATION_MODE && t('document.applyModifications')}
            {activeMode === DECISION_MODE &&
              `${t(
                'document.applyModifications',
              )} - ${getModifiedDecisionsCount()} / ${documentDecisions?.length.toString()}`}
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{
              borderRadius: 2,
              pt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={userCanEditDocument ? 8 : 12}>
                <Header
                  setDocumentPagesInfos={setFilteredDocumentPagesInfos}
                  onPageFiltersChanged={setActivePageFilters}
                  activePageFilters={activePageFilters}
                  onResizingChange={onResizingChange}
                  onToggleBanners={() => setToggleBanners((prev) => !prev)}
                  showBanners={toggleBanners}
                  onToggleDraggable={() => setIsDraggable((prev) => !prev)}
                  isDraggable={isDraggable}
                  isActionDisabled={activeMode === DECISION_MODE}
                  userCanEditDocument={userCanEditDocument}
                  setQuickTypePanelOpen={setQuickTypePanelOpen}
                  activeDocumentStatus={documentDecisions[activeDocumentIndex]?.status}
                  setHasChange={setHasChange}
                />

                <Box
                  sx={{
                    paddingLeft: 1,
                    paddingRight: 1,
                    maxHeight: '45rem',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    scrollbarWidth: 'thin',
                    '::-webkit-scrollbar': { width: 5, background: 'lightgray' },
                    '::-webkit-scrollbar-thumb': {
                      background: 'darkgray',
                    },
                  }}
                >
                  <Thumbnails
                    setDocumentPagesDisplayInfos={setFilteredDocumentPagesInfos}
                    documentPagesDisplayInfos={filteredDocumentPagesInfos}
                    selectedPages={selectedPagesIds}
                    setSelectedPages={setSelectedPagesIds}
                    lang={lang}
                    documentTypes={documentTypes}
                    setHasChange={setHasChange}
                    pagesPerRow={pagesPerRow}
                    showBanners={toggleBanners}
                    isDraggable={isDraggable}
                    isActionDisabled={activeMode === DECISION_MODE || !userCanEditDocument}
                    multipleRequiredDocs={multipleRequiredDocs}
                  />
                </Box>
              </Grid>
              {userCanEditDocument && (
                <Grid item xs={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      m: 1,
                      borderRadius: 2,
                    }}
                  >
                    <Dialog open={quickTypePanelOpen} onClose={() => setQuickTypePanelOpen(false)} hideBackdrop>
                      <Card sx={{ padding: 1 }}>
                        <CardContent>
                          <Stack direction="row" spacing={1.5}>
                            <SelectComponent
                              items={Object.keys(documentTypes)
                                .sort((a, b) => {
                                  const aVal = documentTypes[Number(a)][lang]
                                  const bVal = documentTypes[Number(b)][lang]
                                  return aVal > bVal ? 1 : -1
                                })
                                .map((type) => {
                                  const data: SelectValueListItem = {
                                    label: documentTypes[Number(type)][lang],
                                    value: type,
                                  }
                                  return data
                                })}
                              label="Type-Document"
                              onChange={(val: { target: { value: React.SetStateAction<string> } }) =>
                                setGlobalDocumentType(Number(val.target.value))
                              }
                              value={globalDocumentType}
                            />
                            <SelectComponent
                              items={EApplicantTypeList.map((type) => ({
                                label: t(`enum.beneficiaryType.${type}`),
                                value: type,
                              }))}
                              label="Type-Applicant"
                              onChange={(val: { target: { value: React.SetStateAction<EApplicantType> } }) =>
                                setGlobalApplicantType(val.target.value)
                              }
                              value={globalApplicantType}
                              disabled={creditApplication?.coApplicant === null}
                            />
                          </Stack>
                          <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                            <p>
                              {activeMode === CATEGORIZATION_MODE && ids?.length !== 1
                                ? t('document.allPages')
                                : getDecisionTitle(activeDocumentIndex)}
                              {documentDecisions[activeDocumentIndex]?.applicantType &&
                                !(activeMode === CATEGORIZATION_MODE && ids?.length !== 1) && (
                                  <>
                                    {' '}
                                    -{' '}
                                    {t(`enum.beneficiaryType.${documentDecisions[activeDocumentIndex]?.applicantType}`)}
                                  </>
                                )}
                            </p>
                            <p>
                              <South />
                            </p>
                            <p>
                              {documentTypes[globalDocumentType][lang]} -{' '}
                              {t(`enum.beneficiaryType.${globalApplicantType}`)}
                            </p>
                          </div>
                          <Stack direction="row" justifyContent="space-between" spacing={5}>
                            <Button
                              fullWidth
                              variant="outlined"
                              color="warning"
                              endIcon={<Cancel />}
                              onClick={() => setQuickTypePanelOpen(false)}
                            >
                              {t('common.cancel')}
                            </Button>
                            <Button
                              fullWidth
                              variant="outlined"
                              color="success"
                              endIcon={<DoneIcon />}
                              onClick={handleTypeChange}
                              disabled={
                                (activeMode === DECISION_MODE &&
                                  globalDocumentType === documentDecisions[activeDocumentIndex]?.documentType &&
                                  globalApplicantType === documentDecisions[activeDocumentIndex]?.applicantType) ||
                                isApplyingTransformation
                              }
                            >
                              {t('document.typeChange')}
                            </Button>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Dialog>
                  </Paper>
                  <TabPanel value={activeMode} index={CATEGORIZATION_MODE}>
                    <ActionsDialog
                      title={`${t('document.applyModifications')}?`}
                      open={isOpen}
                      onCancel={handleNoModificationBeforeDecision}
                      onConfirm={handleModificationsBeforeDecision}
                    >
                      <Typography variant="subtitle1" marginTop={3}>
                        <Stack direction="row" style={{ justifyContent: 'left' }}>
                          <Queue color="warning" sx={{ mr: 1 }} />
                          {t('document.createDocumentsMessage')}
                        </Stack>

                        <br />

                        <Stack direction="row" style={{ justifyContent: 'left' }}>
                          <FindInPage color="primary" sx={{ mr: 1 }} />
                          {t('document.returnToAwaitingApprovalMessage')}
                        </Stack>

                        <br />
                        <Stack direction="row" style={{ justifyContent: 'left' }}>
                          <DeleteForever color="error" sx={{ mr: 1 }} />
                          {t('document.deleteDocumentNoPagesMessage')}
                        </Stack>
                      </Typography>
                    </ActionsDialog>

                    <DocumentTypeSelectionSection
                      creditAppRequiredDocs={creditAppRequiredDocs}
                      lang={lang}
                      documentTypes={documentTypes}
                      editSelectedPagesDocType={applyDocumentTypeToSelectedPages}
                      creditAppHasCoApplicant={
                        creditApplication?.coApplicant !== null && creditApplication?.coApplicant !== undefined
                      }
                      applicantType={applicantType}
                      setApplicantType={setApplicantType}
                      editDisabled={editDisabled}
                      multipleRequiredDocs={multipleRequiredDocs}
                    />
                  </TabPanel>
                  <TabPanel value={activeMode} index={DECISION_MODE}>
                    <div style={{ textAlign: 'center' }}>
                      <DocumentDecisionSection
                        documentDecisions={documentDecisions}
                        activeDocumentIndex={activeDocumentIndex}
                        handleNext={handleNext}
                        status={status!}
                        setStatus={setStatus}
                        comment={comment}
                        setComment={setComment}
                        applicantStateIso={creditApplication?.applicant.currentAddress.stateIso ?? null}
                        isRefusedDocumentMissingComment={isRefusedDocumentMissingComment}
                        setHasDecisionMade={setHasDecisionMade}
                        getDecisionTitle={getDecisionTitle}
                        applicantLang={applicantLang!}
                        coapplicantLang={coapplicantLang!}
                      />
                    </div>
                  </TabPanel>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default SplitDocumentPage
